<template>
  <div>
    <div class="p-5 text-center">
      <img src="./images/icon-check-circle.svg" />
      <div class="mt-3 font-weight-bold">訂購完成</div>
    </div>
    <div class="card mt-2 shadow-sm">
      <div class="p-3 detail">
          <div class="d-flex justify-content-between">
            <span>訂單號碼</span>
            <span class="s-text-primary font-weight-bold">240117003262</span>
          </div>

          
          <div class="d-flex justify-content-between mt-2">
            <span>付款方式</span>
            <span class="font-weight-bold">信用卡一次付清</span>
          </div>

          <div class="d-flex justify-content-between mt-2">
            <span>收件人</span>
            <span class="font-weight-bold">黃智彬</span>
          </div>

          <div class="d-flex justify-content-between mt-2">
            <span>收件地址</span>
            <span class="font-weight-bold">新北市永和區永和路一段111號2樓</span>
          </div>

          <div class="d-flex justify-content-between mt-2">
            <span>收件電話</span>
            <span class="font-weight-bold">0911111450</span>
          </div>

          <div class="d-flex justify-content-between mt-2">
            <span>發票類型</span>
            <span class="font-weight-bold">個人-手機條碼載具</span>
          </div>

          <div class="d-flex justify-content-between mt-2">
            <span>訂單金額</span>
            <span class="font-weight-bold">NT$ 3,560</span>
          </div>
        </div>
    </div>
    <div class="card mt-2 d-block shadow-sm">
      <div class="card-header border-0">推薦商品</div>

      <swiper
        :options="{
          slidesPerView: 'auto',
          pagination: { el: '.swiper-pagination', clickable: true },
        }"
        class="product-carousel"
      >
        <swiper-slide
          class="product-carousel__item p-2 py-3 d-flex flex-column"
          v-for="p in recommends"
          :key="p.name"
        >
          <div class="product__photo">
            <img
              :src="`https://picsum.photos/200?${Math.random()}`"
              class="img-fluid"
            />
          </div>

          <div class="product-carousel__item__title product__title">
            {{ p.title }}
          </div>
          <div
            class="
            product-carousel__item__price
              flex-fill
              d-flex
              flex-column
              justify-content-end
            "
          >
            <div class="oprice mr-1">{{ p.oprice }}</div>
            <div class="price">{{ p.price }}</div>
          </div>
          <button class="p-2 btn product-carousel__item__purchase mt-2">加購</button>
        </swiper-slide>

        <div slot="pagination" class="swiper-pagination"></div>
      </swiper>
    </div>

    <div class="sticky-bottom">
      
      <div class="d-flex justify-content-between align-items-center p-3">
        <router-link
            class="btn w-100 btn-secondary btn-lg m-1"
            :to="{ name: 'LiffShopFinished' }"
          >
          查看訂單
          </router-link>
        <router-link
            class="btn w-100 btn-primary btn-lg m-1"
            :to="{ name: 'LiffShopProducts' }"
          >
          繼續購物
          </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import themeColor from "@/mixins/liff/themeColor";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// install Swiper modules

import "swiper/css/swiper.css";

const recommends = [
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
];

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [themeColor],
  data() {
    return {
      recommends,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
.card {
  &-header {
    font-weight: 700;
    font-size: 17px;
  }
}

.products {
  &__item {
    color: #212121;
    text-decoration: none !important;
    display: grid;
    grid-template-columns: 56px auto;
    grid-template-rows: min-content max-content;
    gap: 0px 1rem;
    grid-template-areas:
      "photo title"
      "photo price";

    border-top: solid 1px #eee;

    &__photo {
      grid-area: photo;
    }
    &__title {
      grid-area: title;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    &__price {
      grid-area: price;
    }

    .b-form-spinbutton {
      border: none;
      text-align: center;
      width: auto;
      height: auto;
      padding: 0;

      ::v-deep .btn {
        background: #e7edf6;
        padding: 5px !important;
        border-radius: 8px !important;
      }

      ::v-deep output {
        text-align: center !important;
        padding: 0.5rem 1rem;
        border: none !important;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}

.detail {
  font-size: 14px;

  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1050;

  box-shadow: 0px -2px 4px 0px #0000000d;
}
</style>
